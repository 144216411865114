@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.background {
    font-family: 'Gilroy';
}

/* main */

.page {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    height: 220%;
    left: calc(50% - 945px/2);
    top: 0px;
    background-color: #FFFFFF;
}

.wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    vertical-align: middle;
    width: 65%;
}

.question_body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0px;
    width: 945px;
    height: 100%;
    background-color: #FFFFFF;
}

.question_frame {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
}

/* answer */

.answers_frame {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    -ms-flex-line-pack: justify;
    align-content: space-between;
}

.answers {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.answer_wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 33%;
    margin: 0px 10px;
}

/* table */

.marker {
    width: 27px;
    height: 27px;
    background-color: #BBC8DA;
    text-align: center;
    vertical-align: auto;
    font-weight: 500;
}

.marker p {
    margin: auto;
}

.marker_and_text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
}

.marker {
    margin-right: 15px;
}

/* table */

.select_answer {
    display: table;
    border-collapse: separate;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-spacing: 2px;
    border-color: grey;
    border-spacing: 8px 8px;
}

.select_answer label {
    display: inline-block;
    width: 27px;
    height: 27px;
    line-height: 27px;
    background: #efefef;
    vertical-align: middle;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.select_answer label>input[type=radio] {
    display: none;
}

.select_answer label>span {
    background: #fff;
    border: solid 1px #bbb;
    display: inline-block;
    width: 26px;
    height: 26px;
    vertical-align: middle;
}

.select_answer input[type=radio]:checked+span {
    background: rgb(0, 0, 0);
    border: 2px black solid;
}

.select_answer span:hover {
    background: rgba(0, 0, 0, 0.274);
    cursor: pointer;
}