.page {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-top: 105px;
    margin-bottom: 105px;
    position: absolute;
    width: 945px;
    left: calc(50% - 945px/2);
    height: 400%;
    top: 0px;
    background-color: #FFFFFF;
}

.question_body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0px;
    position: static;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
}


/* answer */

.answers_frame {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0px;
    width: 95%;
    -ms-flex-item-align: start;
    align-self: flex-start;
}

.answers {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 10px 0px;
    -ms-flex-item-align: start;
    align-self: flex-start;
    margin-bottom: 7px;
    margin-left: 20px;
    width: 95%;
}

.wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 31%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    vertical-align: middle;
}

@media (max-width: 992px) {
    /* это будет показано при разрешении монитора до 930 пикселей */
    .page {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 105px 0px;
        position: absolute;
        width: 95%;
        height: 250%;
        left: calc(50% - 95%/2);
        top: 0px;
        background-color: #FFFFFF;
    }
}

@media (min-width: 993px) {
    .page {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 105px 0px;
        position: absolute;
        width: 950px;
        height: 250%;
        left: calc(50% - 950px/2);
        top: 0px;
        background-color: #FFFFFF;
    }
}