.page {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    left: calc(50% - 945px/2);
    top: 0px;
}

.question_body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding: 0px;
    width: 945px;
    background-color: #FFFFFF;
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

.answers {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 30%;
    height: 100%;
}

.title_column {
    margin-bottom: 20px;
}

@media (max-width: 992px) {
    /* это будет показано при разрешении монитора до 930 пикселей */
    .page {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-item-align: center;
        align-self: center;
        padding: 5px 0px;
        position: absolute;
        width: 95%;
        left: calc(50% - 95%/2);
        top: 0px;
    }
    .question_body {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-line-pack: center;
        align-content: center;
        padding: 0px;
        width: 95%;
        background-color: #FFFFFF;
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }
    .answers {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-item-align: center;
        align-self: center;
        width: 90%;
    }
}

@media (min-width: 993px) {
    .page {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 105px 0px;
        position: absolute;
        width: 950px;
        width: 945px;
        left: calc(50% - 950px/2);
        top: 0px;
    }
}