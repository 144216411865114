/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.page {
  /* -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
}
.header {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.main_header {
  font-size: 26px;
  font-family: Gilroy;
  font-weight: bold;
  text-align: center;
}
.sub_header {
  font-size: 24px;
  font-family: Gilroy;
}
.scores_frame {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.score_frame {
  display: flex;
  flex-direction: row;
}
.score {
  width: 190px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mark {
  font-weight: 600;
  font-size: 64px;
  line-height: 78px;
  font-family: Gilroy;
}
.comment {
  font-size: 18px;
  line-height: 22px;
  font-family: Gilroy;
  text-align: center;
}
.btn {
  width: 100%;
  height: 38.5px;
  background: #000000;
  border-radius: 10px;
  color: white !important;
  font-weight: bold;
  font-family: Gilroy;
  font-size: 18px;
  line-height: 25px;
  -webkit-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  border: none;
  -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.28);
  -ms-flex-item-align: start;
  align-self: flex-start;
  margin-bottom: 14px;
  cursor: pointer;
}
.topic_frame{
    font-family: Gilroy;
}
.topic_frame_text{
    margin: 10px 0;
}
@media (min-width: 993px) {
  .MainMenu {
    display: flex;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 950px;
    height: 150%;
    left: calc(50% - 950px / 2);
    top: 0px;
  }
}
@media (max-width: 600px) {
  .score_frame {
    display: flex;
    flex-direction: column;
  }
}
