.btn_turn_back {
    width: 170px;
    height: 48.5px;
    background: #000000;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    font-family: Gilroy;
    font-size: 18px;
    line-height: 22px;
    transition: 0.2s ease-out;
    border: none;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.28);
    align-self: center;
    margin-top: 14px;
    margin-bottom: 7px;
}

.btn_turn_back:hover {
    opacity: 0.9;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.56);
    cursor: pointer;
}

.page {
    display: flex;
    flex-direction: column;
    padding: 55px 0px;
    position: absolute;
    height: 120%;
    width: 945px;
    left: calc(50% - 945px/2);
    top: 0px;
    user-select: none;
}
.header{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.title{
    font-size: 36px;
    line-height: 44px;
    font-weight: 600;
    margin-bottom: 25px;
}
.description{
    margin-bottom: 49px;
}


/* courses */
.courses_wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}