.page {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    left: calc(50% - 945px/2);
    top: 0px;
}

/* body */

.question_body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding: 0px;
    width: 945px;
    height: 100%;
    background-color: #FFFFFF;
}

/* qustion_form */

.question_form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 46%;
    margin-top: 20px;
}

/* video frame */

.video {
    width: 459px;
    height: 275.4px;
    background-color: black;
}

/* answer frame */

.answers_frame {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0px;
    -ms-flex-item-align: start;
    align-self: flex-start;
    width: 48%;
    margin-left: 5%;
}

.wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    vertical-align: middle;
    width: 62%;
}

.pass {
    font-size: 18px;
    line-height: 22px;
    color: gray;
    opacity: 0.8;
    text-decoration: underline;
    background-color: #FFFFFF;
    border: none;
    margin-top: 16px;
}