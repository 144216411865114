.page {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    height: 300%;
    left: calc(50% - 945px/2);
    top: 0px;
    background-color: #FFFFFF;
}

.question_body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0px;
    position: static;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
}

.wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    vertical-align: middle;
    width: 30%;
}

/* answer */

.answers_frame {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0px;
    -ms-flex-item-align: start;
    align-self: flex-start;
    width: 90%;
}

.answers {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 10px 0px;
    -ms-flex-item-align: start;
    align-self: flex-start;
    margin-bottom: 7px;
    margin-left: 20px;
    width: 90%;
}

/* table */

.marker {
    width: 27px;
    height: 27px;
    background-color: #BBC8DA;
    text-align: center;
    vertical-align: auto;
    font-weight: 500;
}

.marker p {
    margin: auto;
}

.marker_and_text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
}

.marker {
    margin-right: 15px;
}

/* table */

.select_answer {
    display: table;
    border-collapse: separate;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-spacing: 2px;
    border-color: grey;
    border-spacing: 8px 8px;
}

.select_answer label {
    display: inline-block;
    width: 27px;
    height: 27px;
    line-height: 27px;
    background: #efefef;
    vertical-align: middle;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.select_answer label>input[type=radio] {
    display: none;
}

.select_answer label>span {
    background: #fff;
    border: solid 1px #bbb;
    display: inline-block;
    width: 26px;
    height: 26px;
    vertical-align: middle;
}

.select_answer input[type=radio]:checked+span {
    background: rgb(0, 0, 0);
}

.select_answer span:hover {
    background: rgba(0, 0, 0, 0.274);
}

@media (max-width: 992px) {
    /* это будет показано при разрешении монитора до 930 пикселей */
    .page {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 105px 0px;
        position: absolute;
        width: 95%;
        height: 300%;
        left: calc(50% - 95%/2);
        top: 0px;
    }
}

@media (min-width: 993px) {
    .page {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 105px 0px;
        position: absolute;
        width: 950px;
        height: 300%;
        left: calc(50% - 950px/2);
        top: 0px;
    }
}