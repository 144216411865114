@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Regular.woff");
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-RegularItalic.woff");
    font-style: italic;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Semibold.woff");
    font-weight: bold;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-SemiboldItalic.wofff");
    font-weight: bold;
    font-style: italic;
}
.page{
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 55px;
    line-height: 60px;
    font-family: Gilroy;
}


.loading{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px ;
    justify-content: center;
    color:black;
    width: 350px;
    height: 100px;
}

.logo{
    animation: pulse 2s infinite linear;
}

@keyframes pulse{
    0%{
        opacity: 1;
    }
    50%{
        opacity: 0.5;
    }
    100%{
        opacity: 1;
    }
}
