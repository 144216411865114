@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Regular.woff");
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-RegularItalic.woff");
    font-style: italic;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Semibold.woff");
    font-weight: bold;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-SemiboldItalic.wofff");
    font-weight: bold;
    font-style: italic;
}

.NotFound_wrapper__24SvH {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.NotFound_image__1BUZx{
    text-align: center;

}
.NotFound_alert__2mjvJ {
    font-family: Gilroy;
    display: flex;
    flex-direction: row;
    font-size: 28px;
    line-height: 33px;
    align-content: center;
    justify-content: center;
    margin-bottom: 20px;

}


@media (max-width: 600px) {
    .NotFound_alert__2mjvJ > img{
       width: 100%;
       height: 100%;
    }


}

.ABCDE_page__2N7B_ {
    display: flex;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    left: calc(50% - 945px/2);
    top: 0px;
}

/* body */

.ABCDE_question_body__10Cwi {
    display: flex;
    flex-direction: row;
    padding: 0px;
    width: 945px;
    height: 100%;
    background-color: #FFFFFF;
}

/* qustion_form */

.ABCDE_question_form__ymLI7 {
    display: flex;
    flex-direction: column;
    width: 46%;
    margin-top: 20px;
}

/* video frame */

.ABCDE_video__1gDnz {
    width: 459px;
    height: 275.4px;
    background-color: black;
}

/* answer frame */

.ABCDE_answers_frame__1J_g5 {
    display: flex;
    flex-direction: column;
    padding: 0px;
    align-self: flex-start;
    width: 48%;
    margin-left: 5%;
}

.ABCDE_wrap__3xYUE {
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    width: 62%;
}

.ABCDE_pass__DNfHR {
    font-size: 18px;
    line-height: 22px;
    color: gray;
    opacity: 0.8;
    text-decoration: underline;
    background-color: #FFFFFF;
    border: none;
    margin-top: 16px;
}
@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Regular.woff");
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-RegularItalic.woff");
    font-style: italic;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Semibold.woff");
    font-weight: bold;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-SemiboldItalic.wofff");
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Bold.woff");
    font-weight: bolder;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-BoldItalic.wofff");
    font-weight: bolder;
    font-style: italic;
}

.Style_page___1yJkX {
    background-color: #FFFFFF;
    font-family: Gilroy;
    font-size: 18px;
    line-height: 25px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Style_question_header__3WfPc {
    width: 100%;
    height: 40px;
    margin-bottom: 29px;
}

.Style_icon_header__3CrlU {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Style_title_bar__3XbkR {
    font-size: 22px;
    line-height: 30px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
}
.Style_title_bar__3XbkR>p{
    margin: 0 0 0 70px; 
}
.Style_end__2G8NW {
    width: 157.5px;
    height: 38.5px;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 0px 0px;
    font-family: Gilroy;
    font-size: 18px;
    line-height: 25px;
    font-weight: bold;
    transition: 0.2s ease-in-out;
}

.Style_end__2G8NW:focus {
    outline: 0;
}

.Style_end__2G8NW:hover {
    background-color: black;
    color: white;
    cursor: pointer;
}

/* result */

.Style_result_frame__3sK14 {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 314px;
    padding: 0px 16px;
}

.Style_result__2o4La {
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 14px;
}

.Style_stats__xxKvA {
    margin-bottom: 14px;
    width: 282px;
}

.Style_wrap__2Bdmo {
    display: flex;
    align-items: center;
    flex-direction: column;
    vertical-align: middle;
}

.Style_btn__28yes {
    width: 100%;
    height: 38.5px;
    background: #000000;
    border-radius: 10px;
    color: white !important;
    font-weight: bold;
    font-family: Gilroy;
    font-size: 18px;
    line-height: 25px;
    transition: 0.2s ease-out;
    border: none;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.28);
    align-self: flex-start;
    margin-bottom: 14px;
}

.Style_btn__28yes:hover {
    opacity: 0.9;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.56);
    cursor: pointer;
}

.Style_btn__28yes:focus {
    outline: 0;
}

.Style_pass__1-uwW {
    font-size: 18px;
    line-height: 25px;
    color: gray;
    opacity: 0.8;
    text-decoration: underline;
    background-color: #FFFFFF;
    border: none;
    transition: 0.2s ease-in;
}

.Style_report__1G1Yy {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    font-size: 14px;
    margin-top: 10px;
    text-decoration: none !important;
}

.Style_pass__1-uwW:focus {
    outline: 0;
}

.Style_pass__1-uwW:hover {
    color: black;
    cursor: pointer;
}

/* topic */

.Style_topic_frame__2DUF4 {}

.Style_topic_frame__2DUF4>div {}

.Style_topic_frame__2DUF4>p {}

.Style_comment_frame__3Tgav {}

.Style_list__1jfdd {
    /* display: -webkit-box;
    display: -ms-flexbox; */
}
.Style_topic_frame__2DUF4 {

}

.Style_topic_frame__2DUF4 > div {

}
.Style_topic_frame__2DUF4 > p {

}

.Style_comment_frame__3Tgav {

}
.Style_list__1jfdd{
    /* display: flex;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; */
}

.Style_list__1jfdd>div {
    /* width: 100%;
    word-wrap: normal;
    margin: 10px 0px; */
}

.Style_video__1Tk0D {
    width: 459px;
    height: 275.4px;
    background-color: black;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Style_video_text__1M7Fd {
    margin: 0 auto;
    color: white;
    position: absolute;
    font-size: 22px;
    font-family: Gilroy;
    line-height: 29px;
}

.Style_btnqnav__30PLb {
    font-family: Gilroy;
    font-size: 18px;
}

.Style_btnqnav__30PLb:focus {
    outline: 0;
}

.Style_inactive__1t_2F {
    opacity: 0.4;
    cursor: default;
    border: none !important;
}

.Style_inactive__1t_2F:hover {
    border: 1px white solid !important;
}

.Style_wrong_answer__Dz9Bg {
    opacity: 0.6;
}

.Style_inactiveNextButton__IHnNo {
    opacity: 0.4;
    cursor: default;
}

.Style_inactiveNextButton__IHnNo:hover {
    opacity: 0.4;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.28);
    cursor: default;
}

.Style_variant_anwer__1Nz0x {
    display: flex;
    flex-direction: row;
    padding: 10px;
}

.Style_variant_anwer__1Nz0x>label {
    width: "15px";
    height: "15px";
    border-radius: "15px";
    border: "1px solid black";
}

.Style_back__2Gy-6 {
    color: black;
}

.Style_back__2Gy-6:focus {
    outline: 0;
}

/* mediaquery */

@media (max-width: 992px) {
    .Style_video__1Tk0D {
        width: 339px;
        height: 165.4px;
        background-color: black;
        margin-bottom: 30px;
    }
}

@media (max-width: 992px) {
    .Style_end__2G8NW {
        width: 30%;
        height: 80%;
        background: #FFFFFF;
        border: 1px solid #000000;
        box-sizing: border-box;
        border-radius: 10px;
        margin: 0px 0px;
        font-family: Gilroy;
        font-size: 14px;
        line-height: 18px;
        font-weight: bold;
        transition: 0.2s ease-in-out;
    }
}

.Style_links__2Jqbv {
    margin: 10px 0px;
    cursor: pointer;
}


.Style_links__2Jqbv:hover {
    opacity: 1;
}

.Answer_answer__2rDEG {
    display: flex;
    flex-direction: column;
    padding: 2% 16px;
    min-width: 96%;
    max-width: 96%;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    border: 1px white solid;
    border-radius: 10px;
    align-self: center;
    margin-bottom: 7px;
    transition: 0.2s linear;
    cursor: pointer;
}

.Answer_answer_withouthover__3bTSl {
    display: flex;
    flex-direction: column;
    padding: 2% 16px;
    min-width: 96%;
    max-width: 96%;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    border: 1px white solid;
    border-radius: 10px;
    align-self: center;
    margin-bottom: 7px;
    transition: 0.2s linear;
}

.Answer_answer_text__2i8zv {
    font-size: 18px;
    margin-bottom: 16px;
    line-height: 25px;
}

.Answer_answer__2rDEG:focus {
    outline: 0;
}

.Answer_answer__2rDEG:hover {
    border: 1px grey solid;
}

.Answer_answer_answered__Macl6 {
    border: 1px black solid;
}

.Answer_answer_text_frame__3G8wh {
    display: inline-flex;
    align-items: baseline;
    flex-direction: row;
    padding: 0px;
    flex: none;
    align-self: flex-start;
    font-size: 16px;
    line-height: 20px;
    margin-top: 21px;
}

.Answer_letter_choice__3tP3f {
    vertical-align: middle;
    font-size: 22px;
    line-height: 27px;
    margin-right: 21px;
}

.Answer_choose__1NVeW {
    font-size: 18px;
    line-height: 25px;
}

.Answer_check__3X81x {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Answer_symbol__3EpVW>svg {
    padding-bottom: 4px;
    padding-right: 8px;
    vertical-align: middle;
}

.Answer_comment__XOQiC {
    font-size: 15px;
    line-height: 23px;
    margin-left: 16px;
    margin-bottom: 16px;
}
a {
    text-decoration: none;
}

ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
}

li {
    color: #000000;
    display: block;
    float: left;
    position: relative;
    text-decoration: none;
    transition-duration: 0.5s;
}

li a {
    color: #000000;
}

li:hover {
    cursor: pointer;
}

ul li ul {
    border: 1px solid black;
    position: absolute;
    border-radius: 5px;
    visibility: hidden;
    opacity: 0;
    min-width: 108px;
    transition: all 0.5s ease;
    display: none;
}

ul li:hover>ul, ul li:focus-within>ul,
/* this is the line we add */

ul li ul:hover {
    visibility: visible;
    opacity: 1;
    display: block;
}

ul li ul li {
    clear: both;
    width: 100%;
}
@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Regular.woff");
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-RegularItalic.woff");
    font-style: italic;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Semibold.woff");
    font-weight: bold;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-SemiboldItalic.wofff");
    font-weight: bold;
    font-style: italic;
}

.Alert_background__3CGQy {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    font-size: 18px;
    font-family: Gilroy;
    background-color: rgba(128, 128, 128, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Alert_alert__2DX2l {
    position: absolute;
    display: flex;
    background-color: white;
    flex-direction: column;
    padding: 30px;
    width: 291.5px;
    height: 250px;
    opacity: 1;
    border: 1px black solid;
    border-radius: 10px;
    align-self: center;
}

.Alert_alert_text__2cDXP {
    margin-bottom: 10px;
}

.Alert_btn_wrapper__1tTPN {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
}

.Alert_btn_turn_back__3AS2t {
    width: 100%;
    height: 38.5px;
    background: #000000;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    font-family: Gilroy;
    font-size: 18px;
    line-height: 22px;
    transition: 0.2s ease-out;
    border: none;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.28);
    align-self: flex-start;
    margin-bottom: 14px;
}

.Alert_btn_turn_back__3AS2t:hover {
    opacity: 0.9;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.56);
    cursor: pointer;
}

.Alert_btn_turn_back__3AS2t:focus {
    outline: 0;
}

.Alert_btn_end__2carl {
    width: 100%;
    height: 38.5px;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 0px 0px;
    font-family: Gilroy;
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    transition: 0.2s ease-in-out;
}

.Alert_btn_end__2carl:focus {
    outline: 0;
}

.Alert_btn_end__2carl:hover {
    background-color: black;
    color: white;
    cursor: pointer;
}

.Alert_btn_hard_end__1Q8Vq {
    font-size: 16px;
    line-height: 20px;
    font-family: Gilroy;
}

.Alert_btn_hard_end__1Q8Vq {
    margin-top: 12px;
    font-size: 18px;
    line-height: 22px;
    color: gray;
    opacity: 0.8;
    text-decoration: underline;
    background-color: #FFFFFF;
    border: none;
    transition: 0.2s ease-in;
}

.Alert_btn_hard_end__1Q8Vq:focus {
    outline: 0;
}

.Alert_btn_hard_end__1Q8Vq:hover {
    color: black;
    cursor: pointer;
}
.HeaderMobile_page__1WdO_ {
    display: flex;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    left: calc(50% - 945px/2);
    top: 0px;
}

.HeaderMobile_header__2bv1U {
    width: 100%;
    height: 350px;
    background-color: white;
    display: flex;
    font-size: 18px;
    line-height: 22px;
    flex-direction: column;
}

.HeaderMobile_nav_panel__r36sn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 15px;
    align-items: baseline;
    margin-top: 15px;
}

.HeaderMobile_tip__3fvQU {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    margin-top: 15px;
    background-color: #E8E8E8;
}

.HeaderMobile_tip1__3fXgA {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    margin-top: 15px;
    margin-bottom: 5px;
    background-color: white;
}

.HeaderMobile_timer__19u9Y {
    display: flex;
    flex-direction: row;
}

.HeaderMobile_title__23-ub {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

@media (max-width: 992px) {
    /* это будет показано при разрешении монитора до 930 пикселей */
    .HeaderMobile_page__1WdO_ {
        display: flex;
        flex-direction: column;
        padding: 5px 0px;
        position: absolute;
        width: 95%;
        left: calc(50% - 95%/2);
        top: 0px;
        font-size: 16px;
    }
}

@media (max-width: 992px) {
    /* это будет показано при разрешении монитора до 930 пикселей */
    .HeaderMobile_header__2bv1U {
        width: 100%;
        height: 100%;
        display: flex;
        font-size: 16px;
        line-height: 20px;
        flex-direction: column;
    }
}

@media (min-width: 993px) {
    .HeaderMobile_page__1WdO_ {
        display: flex;
        flex-direction: column;
        padding: 105px 0px;
        position: absolute;
        width: 950px;
        left: calc(50% - 950px/2);
        top: 0px;
    }
}

/* overlay */

.HeaderMobile_overlay__1_-6m {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: white;
    overflow-x: hidden;
    transition: 0.5s;
}

.HeaderMobile_overlay-content__mrrE1 {
    position: relative;
    top: 25%;
    width: 60%;
    text-align: center;
    margin-top: 30px;
}

.HeaderMobile_overlay__1_-6m a {
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    color: black;
    display: block;
    transition: 0.3s;
}

.HeaderMobile_overlay__1_-6m a:hover, .HeaderMobile_overlay__1_-6m a:focus {
    color: black;
}

.HeaderMobile_overlay__1_-6m .HeaderMobile_closebtn__1Z4Mz {
    position: absolute;
    top: 1%;
    left: 83%;
    font-size: 60px;
}
.Survey_btn_turn_back__1qRFz {
    width: 170px;
    height: 48.5px;
    background: #000000;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    font-family: Gilroy;
    font-size: 18px;
    line-height: 22px;
    transition: 0.2s ease-out;
    border: none;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.28);
    align-self: center;
    margin-top: 14px;
    margin-bottom: 7px;
}

.Survey_btn_turn_back__1qRFz:hover {
    opacity: 0.9;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.56);
    cursor: pointer;
}

.Survey_page__1Am-A {
    display: flex;
    flex-direction: column;
    padding: 55px 0px;
    position: absolute;
    height: 120%;
    width: 945px;
    left: calc(50% - 945px/2);
    top: 0px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.Survey_header__3PpKd{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.Survey_title__61LQ0{
    font-size: 36px;
    line-height: 44px;
    font-weight: 600;
    margin-bottom: 25px;
}
.Survey_description__27kjQ{
    margin-bottom: 49px;
}


/* courses */
.Survey_courses_wrapper__1k7wP{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}
.ABCD_page__ypYlT {
    display: flex;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    left: calc(50% - 945px/2);
    top: 0px;
}

/* body */

.ABCD_question_body__GFsKw {
    display: flex;
    flex-direction: row;
    padding: 0px;
    width: 945px;
    height: 100%;
    background-color: #FFFFFF;
}

/* qustion_form */

.ABCD_question_form__YmJ4P {
    display: flex;
    flex-direction: column;
    width: 46%;
    margin-top: 20px;
}

/* video frame */

.ABCD_video__1y_Cm {
    width: 459px;
    height: 275.4px;
    background-color: black;
}

/* answer frame */

.ABCD_answers_frame__1G0Em {
    display: flex;
    flex-direction: column;
    padding: 0px;
    align-self: flex-start;
    width: 48%;
    margin-left: 4.5%;
}

.ABCD_wrap__-EvJ- {
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    width: 62%;
}

.ABCD_pass__9Atxh {
    font-size: 18px;
    line-height: 22px;
    color: gray;
    opacity: 0.8;
    text-decoration: underline;
    background-color: #FFFFFF;
    border: none;
    margin-top: 16px;
}
.Logic_couples_4_4_background__1nSXT {
    font-family: 'Gilroy';
}

/* main */

.Logic_couples_4_4_page__2Muze {
    display: flex;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    height: 180%;
    left: calc(50% - 945px/2);
    top: 0px;
    background-color: #FFFFFF;
}

.Logic_couples_4_4_question_body__3xp-4 {
    display: flex;
    flex-direction: row;
    padding: 0px;
    position: static;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
}

.Logic_couples_4_4_wrap__2bExl {
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    width: 66%;
}

/* answer */

.Logic_couples_4_4_answers_frame__4Oldh {
    display: flex;
    flex-direction: column;
    padding: 0px;
    align-self: flex-start;
}

.Logic_couples_4_4_answers__1TNkH {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    align-self: flex-start;
    margin-bottom: 7px;
    margin-left: 20px;
}

.Logic_couples_4_4_answer_wrap__1Ow69 {
    display: flex;
    flex-direction: column;
    width: 33%;
    margin: 0px 10px;
}

/* table */

.Logic_couples_4_4_marker__2qUgk {
    width: 27px;
    height: 27px;
    background-color: #BBC8DA;
    text-align: center;
    vertical-align: auto;
    font-weight: 500;
}

.Logic_couples_4_4_marker__2qUgk p {
    margin: auto;
}

.Logic_couples_4_4_marker_and_text__3M9a2 {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Logic_couples_4_4_marker__2qUgk {
    margin-right: 15px;
}

/* table */

.Logic_couples_4_4_select_answer__209Jf {
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    border-spacing: 2px;
    border-color: grey;
    border-spacing: 8px 8px;
}

.Logic_couples_4_4_select_answer__209Jf label {
    display: inline-block;
    width: 27px;
    height: 27px;
    line-height: 27px;
    background: #efefef;
    vertical-align: middle;
    align-items: center;
}

.Logic_couples_4_4_select_answer__209Jf label>input[type=radio] {
    display: none;
}

.Logic_couples_4_4_select_answer__209Jf label>span {
    background: #fff;
    border: solid 1px #bbb;
    display: inline-block;
    width: 26px;
    height: 26px;
    vertical-align: middle;
}

.Logic_couples_4_4_select_answer__209Jf input[type=radio]:checked+span {
    background: rgb(0, 0, 0);
}

.Logic_couples_4_4_select_answer__209Jf span:hover {
    background: rgba(0, 0, 0, 0.274);
}
.Couple_Answer_answer__2rozn {
    display: flex;
    flex-direction: column;
    padding: 2% 16px;
    min-width: 85%;
    max-width: 50%;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    border: 1px white solid;
    border-radius: 10px;
    align-self: center;
    margin-bottom: 7px;
    transition: 0.2s linear;
}

.Couple_Answer_answer_withouthover__15XIT {
    display: flex;
    flex-direction: column;
    padding: 2% 16px;
    min-width: 85%;
    max-width: 50%;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    border: 1px white solid;
    border-radius: 10px;
    align-self: center;
    margin-bottom: 7px;
    transition: 0.2s linear;
}

.Couple_Answer_answer_text__2A3qY {
    font-size: 18px;
    margin-bottom: 16px;
}

.Couple_Answer_answer__2rozn:focus {
    outline: 0;
}

.Couple_Answer_answer__2rozn:hover {
    border: 1px black solid;
    cursor: pointer;
}

.Couple_Answer_borderr__15Jcf {
    border: 1px black solid;
}

.Couple_Answer_answer_text_frame__2Pz8l {
    display: inline-flex;
    align-items: baseline;
    flex-direction: row;
    padding: 0px;
    flex: none;
    align-self: flex-start;
    font-size: 16px;
    line-height: 20px;
    margin-top: 21px;
    margin-bottom: 16px;
}

.Couple_Answer_answer_text__2A3qY {
    margin-left: 21px;
}

.Couple_Answer_letter_choice__19w0Z {
    vertical-align: middle;
    font-size: 22px;
    line-height: 27px;
    margin-right: 21px;
}

.Couple_Answer_choose__3rAqg {
    font-size: 18px;
    line-height: 22px;
}

.Couple_Answer_check__Ye2aN {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Couple_Answer_symbol__24Wlq>svg {
    padding-bottom: 4px;
    padding-right: 8px;
    vertical-align: middle;
}

.Couple_Answer_comment__3MEvH {
    font-size: 15px;
    line-height: 19px;
    margin-left: 16px;
    margin-bottom: 16px;
}
.CoupleOption_answer__2IQ_Q {
    display: flex;
    flex-direction: column;
    padding: 2% 16px;
    min-width: 96%;
    max-width: 96%;
    background: #FFFFFF;
    border-radius: 10px;
    align-self: center;
    margin-bottom: 7px;
    transition: 0.2s linear;
}

.CoupleOption_answer_text__2nUFl {
    font-size: 18px;
    margin-bottom: 16px;
}

.CoupleOption_answer_text_frame__3eD1t {
    display: inline-flex;
    align-items: baseline;
    flex-direction: row;
    padding: 0px;
    flex: none;
    align-self: flex-start;
    font-size: 16px;
    line-height: 20px;
    margin-top: 21px;
}

.CoupleOption_letter_choice__1SK_T {
    vertical-align: middle;
    font-size: 22px;
    line-height: 27px;
    margin-right: 21px;
}

.CoupleOption_check__2d0tp {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}
.Logic_couples_4_5_background__dJQBv {
    font-family: 'Gilroy';
}

/* main */

.Logic_couples_4_5_page__3Y-B5 {
    display: flex;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    height: 220%;
    left: calc(50% - 945px/2);
    top: 0px;
    background-color: #FFFFFF;
}

.Logic_couples_4_5_wrap__vk3YX {
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    width: 65%;
}

.Logic_couples_4_5_question_body__1eTWh {
    display: flex;
    flex-direction: column;
    padding: 0px;
    width: 945px;
    height: 100%;
    background-color: #FFFFFF;
}

.Logic_couples_4_5_question_frame__36BbY {
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* answer */

.Logic_couples_4_5_answers_frame__15PZV {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-content: space-between;
}

.Logic_couples_4_5_answers__14Ie5 {
    display: flex;
    flex-direction: row;
}

.Logic_couples_4_5_answer_wrap__3QabB {
    display: flex;
    flex-direction: column;
    width: 33%;
    margin: 0px 10px;
}

/* table */

.Logic_couples_4_5_marker__NkQGT {
    width: 27px;
    height: 27px;
    background-color: #BBC8DA;
    text-align: center;
    vertical-align: auto;
    font-weight: 500;
}

.Logic_couples_4_5_marker__NkQGT p {
    margin: auto;
}

.Logic_couples_4_5_marker_and_text__3KChN {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Logic_couples_4_5_marker__NkQGT {
    margin-right: 15px;
}

/* table */

.Logic_couples_4_5_select_answer__3QGsd {
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    border-spacing: 2px;
    border-color: grey;
    border-spacing: 8px 8px;
}

.Logic_couples_4_5_select_answer__3QGsd label {
    display: inline-block;
    width: 27px;
    height: 27px;
    line-height: 27px;
    background: #efefef;
    vertical-align: middle;
    align-items: center;
}

.Logic_couples_4_5_select_answer__3QGsd label>input[type=radio] {
    display: none;
}

.Logic_couples_4_5_select_answer__3QGsd label>span {
    background: #fff;
    border: solid 1px #bbb;
    display: inline-block;
    width: 26px;
    height: 26px;
    vertical-align: middle;
}

.Logic_couples_4_5_select_answer__3QGsd input[type=radio]:checked+span {
    background: rgb(0, 0, 0);
    border: 2px black solid;
}

.Logic_couples_4_5_select_answer__3QGsd span:hover {
    background: rgba(0, 0, 0, 0.274);
    cursor: pointer;
}
.Double_Open_background__2p5Z4 {
    font-family: 'Gilroy';
}

/* main */

.Double_Open_page__3NayT {
    display: flex;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    height: 190%;
    left: calc(50% - 945px/2);
    top: 0px;
    background-color: #FFFFFF;
}

.Double_Open_inp__2xzUE {
    padding: 8px 16px;
    width: 102px;
    height: 33px;
    background: #FFFFFF;
    border: 1px solid black;
    box-sizing: border-box;
    border-radius: 10px;
    align-self: flex-start;
    font-size: 18px;
    line-height: 22px;
    font-family: 'Gilroy';
}

.Double_Open_inp__2xzUE:focus {
    outline: 0;
    border: 1px black solid;
}

/* answers */

.Double_Open_answers_frame__18o0h {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
}

.Double_Open_answer__3tWT4 {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.Double_Open_variant__2LwaJ {
    display: inline-flex;
    align-items: baseline;
    flex-direction: row;
    padding: 0px;
    flex: none;
    align-self: flex-start;
    font-size: 16px;
    line-height: 20px;
    margin-top: 21px;
}

.Double_Open_variant__2LwaJ>div {
    font-size: 18px;
    line-height: 22px;
}

.Double_Open_number__2hdsz {
    vertical-align: middle;
    font-size: 22px;
    line-height: 27px;
    margin-right: 21px;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Double_Open_icon__9qT4x>svg {
    padding-bottom: 4px;
    padding-right: 8px;
    vertical-align: middle;
}

.Double_Open_comment__1Up41 {
    font-size: 16px;
}

.Double_Open_check__32EZa {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Double_Open_correct_answer__1-6tt {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Double_Open_correct_number__2LQJ8 {
    margin-top: 17px;
}

/* result */

.Double_Open_wrap__2z78s {
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    width: 30%;
}
.Input_Answer_inp__2G3LK {
    margin-top: 10px;
    margin-left: 25px;
    padding: 8px 16px;
    width: 102px;
    height: 33px;
    background: #FFFFFF;
    border: 1px solid #7c7c7c;
    box-sizing: border-box;
    border-radius: 10px;
    align-self: flex-start;
    font-size: 18px;
    line-height: 22px;
    font-family: 'Gilroy';
}

.Input_Answer_inp__2G3LK:focus {
    outline: 0;
    border: 1px solid black;
}

.Input_Answer_answer__3iKfC {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.Input_Answer_variant__24Dgc {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 0px;
    flex: none;
    align-self: flex-start;
    font-size: 16px;
    line-height: 20px;
    margin-top: 21px;
}

.Input_Answer_variant__24Dgc>div {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: 22px;
}

.Input_Answer_number__2i93o {
    vertical-align: middle;
    font-size: 22px;
    line-height: 27px;
    margin-right: 21px;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Input_Answer_icon__1anqx>svg {
    vertical-align: middle;
}

.Input_Answer_comment___FZGd {
    font-size: 16px;
}

.Input_Answer_check__3WO7G {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Input_Answer_correct_answer__2-JYL {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Input_Answer_correct_number__1Hxwu {
    margin-top: 17px;
}
.Open_background__3bLm_ {
    font-family: 'Gilroy';
}

/* main */

.Open_page__olbot {
    display: flex;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    height: 100%;
    left: calc(50% - 945px/2);
    top: 0px;
    background-color: #FFFFFF;
}

/* answers */

.Open_main_answers__2i53T>p {
    font-size: 18px;
}

.Open_answers_frame__2yPiy {
    display: flex;
    flex-direction: row;
}

.Open_answer__Mp_IQ {
    width: 50;
    display: flex;
    flex-direction: column;
}

.Open_variant__yYuSz {
    display: flex;
    flex-direction: row;
    font-size: 18px;
}

.Open_inp__3TuZa {
    padding: 8px 16px;
    width: 102px;
    height: 33px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    box-sizing: border-box;
    border-radius: 10px;
    align-self: flex-start;
    font-size: 18px;
    line-height: 22px;
    font-family: 'Gilroy';
}

.Open_inp__3TuZa:focus {
    outline: 0;
}

.Open_correct_answer__eYu3s {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Open_correct_number__3MLHx {
    margin-top: 17px;
    font-size: 18px;
    line-height: 22px;
}

.Open_comment__QPV4K {
    font-size: 16px;
}

/* result */

.Open_result__11MLx {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px;
}

.Open_result_frame__22F-y {
    display: flex;
    flex-direction: column;
    padding: 16px;
}

.Open_correct_message__2u2Y5 {
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
}

.Open_correct_result__2rwTw {
    font-size: 18px;
    margin-top: 14px;
}

.Open_btn__3ydG3 {
    width: 282px;
    height: 38.5px;
    background: #000000;
    border-radius: 10px;
    margin-top: 14px;
    color: white;
    font-size: 18px;
    line-height: 22px;
    font-family: 'Gilroy';
    font-weight: bold;
}
.Super_Open_background__3Wz4T {
    font-family: 'Gilroy';
}

/* main */

.Super_Open_page__10u8l {
    display: flex;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    height: 100%;
    left: calc(50% - 945px/2);
    top: 0px;
    background-color: #FFFFFF;
}

.Super_Open_question_header__3EOmz {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 85.5px;
}

.Super_Open_title_bar__1gqqj {
    font-size: 22px;
    line-height: 27px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
}

.Super_Open_title__fpdz- {
    font-size: 18px;
    line-height: 22px;
}

.Super_Open_end__16XXM {
    width: 157.5px;
    height: 42.5px;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 0px 0px;
    font-size: 18px;
    line-height: 22px;
    font-family: 'Gilroy';
}

.Super_Open_question_frame__3OChr p {
    font-size: 18px;
    line-height: 22px;
}

/* result */

.Super_Open_result__2PV5D {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px;
}

.Super_Open_result_frame__2KFEq {
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 298px;
    height: 205px;
}

.Super_Open_correct_message__3OX4J {
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
}

.Super_Open_correct_result__2wUxO {
    font-size: 18px;
    margin-top: 14px;
}

.Super_Open_btn__sV13l {
    width: 298px;
    height: 42.5px;
    background: #000000;
    border-radius: 10px;
    margin-top: 14px;
    color: white;
    font-size: 18px;
    line-height: 22px;
    font-family: 'Gilroy';
    font-weight: 600;
}

.Super_Open_topic_frame__1NL2W {
    font-size: 18px;
    line-height: 24px;
}

.Super_Open_stats__3lYsH {
    font-size: 18px;
    line-height: 22px;
}

.Super_Open_topic_frame_text__DNoAV {
    font-size: 18px;
    line-height: 22px;
}

.Super_Open_comment_frame__2oM2T {
    font-size: 18px;
    line-height: 24px;
}

.Super_Open_comment_frame_text__zUFIy {
    font-size: 18px;
    line-height: 22px;
}

.Super_Open_video_explanation_frame__1f17l {
    font-size: 18px;
    line-height: 24px;
}

/* video frame */

.Super_Open_video__1D1H2 {
    width: 459px;
    height: 275.4px;
    background-color: black;
}
.ABCDE_OneColumn_background__3FF9M {
    font-family: 'Gilroy';
}

/* main */

.ABCDE_OneColumn_background__3FF9M {
    display: flex;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    height: 270%;
    left: calc(50% - 945px/2);
    top: 0px;
    background-color: #FFFFFF;
}

.ABCDE_OneColumn_page__1pmst {
    display: flex;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    height: 270%;
    left: calc(50% - 945px/2);
    top: 0px;
    background-color: #FFFFFF;
}

.ABCDE_OneColumn_question_body__ZhtG4 {
    display: flex;
    flex-direction: column;
    padding: 0px;
    position: static;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
}

.ABCDE_OneColumn_title__Ozn5g {
    font-size: 20px;
    line-height: 24px;
}

/* answer */

.ABCDE_OneColumn_answers_frame__3ruQT {
    display: flex;
    width: 95%;
    flex-direction: column;
    padding: 0px;
    align-self: flex-start;
}

.ABCDE_OneColumn_answers__3WGWX {
    display: flex;
    width: 95%;
    flex-direction: column;
    padding: 10px 0px;
    align-self: flex-start;
    margin-bottom: 7px;
    margin-left: 20px;
}

/* result frame */

.ABCDE_OneColumn_wrap__d8Wig {
    display: flex;
    width: 30%;
    flex-direction: column;
    vertical-align: middle;
}

/* video frame */

@media (max-width: 992px) {
    .ABCDE_OneColumn_page__1pmst {
        display: flex;
        flex-direction: column;
        padding: 10px 0px;
        position: absolute;
        width: 95%;
        height: 180%;
        left: calc(50% - 95%/2);
        top: 0px;
    }
}

@media (min-width: 993px) {
    .ABCDE_OneColumn_page__1pmst {
        display: flex;
        flex-direction: column;
        padding: 105px 0px;
        position: absolute;
        width: 950px;
        height: 150%;
        left: calc(50% - 950px/2);
        top: 0px;
    }
}
.ABCD_OneColumn_page__fEzkQ {
    display: flex;
    flex-direction: column;
    padding-top: 105px;
    margin-bottom: 105px;
    position: absolute;
    width: 945px;
    left: calc(50% - 945px/2);
    height: 400%;
    top: 0px;
    background-color: #FFFFFF;
}

.ABCD_OneColumn_question_body__2nvFZ {
    display: flex;
    flex-direction: column;
    padding: 0px;
    position: static;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
}


/* answer */

.ABCD_OneColumn_answers_frame__6-MUq {
    display: flex;
    flex-direction: column;
    padding: 0px;
    width: 95%;
    align-self: flex-start;
}

.ABCD_OneColumn_answers__3jaO4 {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    align-self: flex-start;
    margin-bottom: 7px;
    margin-left: 20px;
    width: 95%;
}

.ABCD_OneColumn_wrap__1ah0A {
    display: flex;
    width: 31%;
    flex-direction: column;
    vertical-align: middle;
}

@media (max-width: 992px) {
    /* это будет показано при разрешении монитора до 930 пикселей */
    .ABCD_OneColumn_page__fEzkQ {
        display: flex;
        flex-direction: column;
        padding: 105px 0px;
        position: absolute;
        width: 95%;
        height: 250%;
        left: calc(50% - 95%/2);
        top: 0px;
        background-color: #FFFFFF;
    }
}

@media (min-width: 993px) {
    .ABCD_OneColumn_page__fEzkQ {
        display: flex;
        flex-direction: column;
        padding: 105px 0px;
        position: absolute;
        width: 950px;
        height: 250%;
        left: calc(50% - 950px/2);
        top: 0px;
        background-color: #FFFFFF;
    }
}
.Double_Open_OneColumn_background__1wvir {
    font-family: 'Gilroy';
}

/* main */

.Double_Open_OneColumn_page__2HxXS {
    display: flex;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    height: 220%;
    left: calc(50% - 945px/2);
    top: 0px;
    background-color: #FFFFFF;
}

.Double_Open_OneColumn_inp__26moV {
    padding: 8px 16px;
    width: 102px;
    height: 33px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    box-sizing: border-box;
    border-radius: 10px;
    align-self: flex-start;
    font-size: 18px;
    line-height: 22px;
    font-family: 'Gilroy';
}

.Double_Open_OneColumn_inp__26moV:focus {
    outline: 0;
}

/* answers */

.Double_Open_OneColumn_answers_frame__2cKiw {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.Double_Open_OneColumn_answer__1e6Z3 {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.Double_Open_OneColumn_variant__3fFi9 {
    display: inline-flex;
    align-items: baseline;
    flex-direction: row;
    padding: 0px;
    flex: none;
    align-self: flex-start;
    font-size: 16px;
    line-height: 20px;
    margin-top: 21px;
}

.Double_Open_OneColumn_variant__3fFi9>div {
    font-size: 18px;
    line-height: 22px;
}

.Double_Open_OneColumn_number__1MMYf {
    vertical-align: middle;
    font-size: 22px;
    line-height: 27px;
    margin-right: 21px;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Double_Open_OneColumn_icon__3eS1m>svg {
    padding-bottom: 4px;
    padding-right: 8px;
    vertical-align: middle;
}

.Double_Open_OneColumn_comment__cyQYM {
    font-size: 16px;
}

.Double_Open_OneColumn_check__1xDtB {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Double_Open_OneColumn_correct_answer__2yro6 {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Double_Open_OneColumn_correct_number__1Y2p0 {
    margin-top: 17px;
}

/* result */

.Double_Open_OneColumn_wrap__1bOdG {
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    width: 30%;
}

@media (max-width: 992px) {
    .Double_Open_OneColumn_page__2HxXS {
        display: flex;
        flex-direction: column;
        padding: 105px 0px;
        position: absolute;
        width: 95%;
        height: 220%;
        left: calc(50% - 95%/2);
        top: 0px;
    }
}

@media (min-width: 993px) {
    .Double_Open_OneColumn_page__2HxXS {
        display: flex;
        flex-direction: column;
        padding: 105px 0px;
        position: absolute;
        width: 950px;
        height: 220%;
        left: calc(50% - 950px/2);
        top: 0px;
    }
}
.Logic_Couples_4_4_OneColumn_background__BusfW {
    font-family: 'Gilroy';
}

/* main */

.Logic_Couples_4_4_OneColumn_page__3Fx0l {
    display: flex;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    height: 280%;
    left: calc(50% - 945px/2);
    top: 0px;
    background-color: #FFFFFF;
}

.Logic_Couples_4_4_OneColumn_question_body__2wCHx {
    display: flex;
    flex-direction: column;
    padding: 0px;
    position: static;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
}

.Logic_Couples_4_4_OneColumn_wrap__33Ax- {
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    width: 30%;
}

/* answer */

.Logic_Couples_4_4_OneColumn_answers_frame__IuzH2 {
    display: flex;
    flex-direction: column;
    padding: 0px;
    align-self: flex-start;
}

.Logic_Couples_4_4_OneColumn_answers__1U-ZX {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    align-self: flex-start;
    margin-bottom: 7px;
    margin-left: 20px;
}

/* table */

.Logic_Couples_4_4_OneColumn_marker__5cPUN {
    width: 27px;
    height: 27px;
    background-color: #BBC8DA;
    text-align: center;
    vertical-align: auto;
    font-weight: 500;
}

.Logic_Couples_4_4_OneColumn_marker__5cPUN p {
    margin: auto;
}

.Logic_Couples_4_4_OneColumn_marker_and_text__Kg65f {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Logic_Couples_4_4_OneColumn_marker__5cPUN {
    margin-right: 15px;
}

/* table */

.Logic_Couples_4_4_OneColumn_select_answer__312HG {
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    border-spacing: 2px;
    border-color: grey;
    border-spacing: 8px 8px;
}

.Logic_Couples_4_4_OneColumn_select_answer__312HG label {
    display: inline-block;
    width: 27px;
    height: 27px;
    line-height: 27px;
    background: #efefef;
    vertical-align: middle;
    align-items: center;
}

.Logic_Couples_4_4_OneColumn_select_answer__312HG label>input[type=radio] {
    display: none;
}

.Logic_Couples_4_4_OneColumn_select_answer__312HG label>span {
    background: #fff;
    border: solid 1px #bbb;
    display: inline-block;
    width: 26px;
    height: 26px;
    vertical-align: middle;
}

.Logic_Couples_4_4_OneColumn_select_answer__312HG input[type=radio]:checked+span {
    background: rgb(0, 0, 0);
}

.Logic_Couples_4_4_OneColumn_select_answer__312HG span:hover {
    background: rgba(0, 0, 0, 0.274);
}

@media (max-width: 992px) {
    /* это будет показано при разрешении монитора до 930 пикселей */
    .Logic_Couples_4_4_OneColumn_page__3Fx0l {
        display: flex;
        flex-direction: column;
        padding: 105px 0px;
        position: absolute;
        width: 95%;
        height: 280%;
        left: calc(50% - 95%/2);
        top: 0px;
    }
}

@media (min-width: 993px) {
    .Logic_Couples_4_4_OneColumn_page__3Fx0l {
        display: flex;
        flex-direction: column;
        padding: 105px 0px;
        position: absolute;
        width: 950px;
        height: 280%;
        left: calc(50% - 950px/2);
        top: 0px;
    }
}
.Logic_Couples_4_5_OneColumn_page__2ky1c {
    display: flex;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    height: 300%;
    left: calc(50% - 945px/2);
    top: 0px;
    background-color: #FFFFFF;
}

.Logic_Couples_4_5_OneColumn_question_body__3HB-5 {
    display: flex;
    flex-direction: column;
    padding: 0px;
    position: static;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
}

.Logic_Couples_4_5_OneColumn_wrap__1TSQi {
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    width: 30%;
}

/* answer */

.Logic_Couples_4_5_OneColumn_answers_frame__1pZRX {
    display: flex;
    flex-direction: column;
    padding: 0px;
    align-self: flex-start;
    width: 90%;
}

.Logic_Couples_4_5_OneColumn_answers__1ZA75 {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    align-self: flex-start;
    margin-bottom: 7px;
    margin-left: 20px;
    width: 90%;
}

/* table */

.Logic_Couples_4_5_OneColumn_marker__PjsxI {
    width: 27px;
    height: 27px;
    background-color: #BBC8DA;
    text-align: center;
    vertical-align: auto;
    font-weight: 500;
}

.Logic_Couples_4_5_OneColumn_marker__PjsxI p {
    margin: auto;
}

.Logic_Couples_4_5_OneColumn_marker_and_text__I5Tse {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.Logic_Couples_4_5_OneColumn_marker__PjsxI {
    margin-right: 15px;
}

/* table */

.Logic_Couples_4_5_OneColumn_select_answer__3FEtS {
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    border-spacing: 2px;
    border-color: grey;
    border-spacing: 8px 8px;
}

.Logic_Couples_4_5_OneColumn_select_answer__3FEtS label {
    display: inline-block;
    width: 27px;
    height: 27px;
    line-height: 27px;
    background: #efefef;
    vertical-align: middle;
    align-items: center;
}

.Logic_Couples_4_5_OneColumn_select_answer__3FEtS label>input[type=radio] {
    display: none;
}

.Logic_Couples_4_5_OneColumn_select_answer__3FEtS label>span {
    background: #fff;
    border: solid 1px #bbb;
    display: inline-block;
    width: 26px;
    height: 26px;
    vertical-align: middle;
}

.Logic_Couples_4_5_OneColumn_select_answer__3FEtS input[type=radio]:checked+span {
    background: rgb(0, 0, 0);
}

.Logic_Couples_4_5_OneColumn_select_answer__3FEtS span:hover {
    background: rgba(0, 0, 0, 0.274);
}

@media (max-width: 992px) {
    /* это будет показано при разрешении монитора до 930 пикселей */
    .Logic_Couples_4_5_OneColumn_page__2ky1c {
        display: flex;
        flex-direction: column;
        padding: 105px 0px;
        position: absolute;
        width: 95%;
        height: 300%;
        left: calc(50% - 95%/2);
        top: 0px;
    }
}

@media (min-width: 993px) {
    .Logic_Couples_4_5_OneColumn_page__2ky1c {
        display: flex;
        flex-direction: column;
        padding: 105px 0px;
        position: absolute;
        width: 950px;
        height: 300%;
        left: calc(50% - 950px/2);
        top: 0px;
    }
}
.BioTriples_page__3NzIO {
    display: flex;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    left: calc(50% - 945px/2);
    top: 0px;
}

.BioTriples_question_body__3tJ2D {
    display: flex;
    flex-direction: row;
    padding: 0px;
    width: 945px;
    background-color: #FFFFFF;
    justify-content: space-around;
}

.BioTriples_answers__1hAy4 {
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 100%;
}

.BioTriples_title_column__Sqfil {
    margin-bottom: 20px;
}

@media (max-width: 992px) {
    /* это будет показано при разрешении монитора до 930 пикселей */
    .BioTriples_page__3NzIO {
        display: flex;
        flex-direction: column;
        align-self: center;
        padding: 5px 0px;
        position: absolute;
        width: 95%;
        left: calc(50% - 95%/2);
        top: 0px;
    }
    .BioTriples_question_body__3tJ2D {
        display: flex;
        flex-direction: column;
        align-content: center;
        padding: 0px;
        width: 95%;
        background-color: #FFFFFF;
        justify-content: space-around;
    }
    .BioTriples_answers__1hAy4 {
        display: flex;
        flex-direction: column;
        align-self: center;
        width: 90%;
    }
}

@media (min-width: 993px) {
    .BioTriples_page__3NzIO {
        display: flex;
        flex-direction: column;
        padding: 105px 0px;
        position: absolute;
        width: 950px;
        width: 945px;
        left: calc(50% - 950px/2);
        top: 0px;
    }
}
.Geo_History_3_7_page__2RLn2 {
    display: flex;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 945px;
    left: calc(50% - 945px/2);
    top: 0px;
}

.Geo_History_3_7_question_body__3xtrv {
    display: flex;
    flex-direction: row;
    padding: 0px;
    width: 945px;
    height: auto;
    background-color: #FFFFFF;
    justify-content: space-around;
}

.Geo_History_3_7_answers__27Mrp {
    display: flex;
    flex-direction: column;
    width: 40%;
}

@media (max-width: 992px) {
    /* это будет показано при разрешении монитора до 930 пикселей */
    .Geo_History_3_7_page__2RLn2 {
        display: flex;
        flex-direction: column;
        padding: 5px 0px;
        position: absolute;
        width: 95%;
        left: calc(50% - 95%/2);
        top: 0px;
    }
    .Geo_History_3_7_question_body__3xtrv {
        display: flex;
        flex-direction: column;
        padding: 0px;
        width: 95%;
        background-color: #FFFFFF;
        justify-content: space-around;
    }
    .Geo_History_3_7_answers__27Mrp {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

@media (min-width: 993px) {
    .Geo_History_3_7_page__2RLn2 {
        display: flex;
        flex-direction: column;
        padding: 105px 0px;
        position: absolute;
        width: 950px;
        left: calc(50% - 950px/2);
        top: 0px;
    }
}
@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Regular.woff");
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-RegularItalic.woff");
    font-style: italic;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Semibold.woff");
    font-weight: bold;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-SemiboldItalic.wofff");
    font-weight: bold;
    font-style: italic;
}
.LoadingScreen_page__y07q5{
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 55px;
    line-height: 60px;
    font-family: Gilroy;
}


.LoadingScreen_loading__3UASA{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px ;
    justify-content: center;
    color:black;
    width: 350px;
    height: 100px;
}

.LoadingScreen_logo__1Fnjv{
    -webkit-animation: LoadingScreen_pulse__15OA4 2s infinite linear;
            animation: LoadingScreen_pulse__15OA4 2s infinite linear;
}

@-webkit-keyframes LoadingScreen_pulse__15OA4{
    0%{
        opacity: 1;
    }
    50%{
        opacity: 0.5;
    }
    100%{
        opacity: 1;
    }
}

@keyframes LoadingScreen_pulse__15OA4{
    0%{
        opacity: 1;
    }
    50%{
        opacity: 0.5;
    }
    100%{
        opacity: 1;
    }
}

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.MainMenu_page__1JGuj {
  /* -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
}
.MainMenu_header__3lPR_ {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.MainMenu_main_header__2zJht {
  font-size: 26px;
  font-family: Gilroy;
  font-weight: bold;
  text-align: center;
}
.MainMenu_sub_header__2SBvI {
  font-size: 24px;
  font-family: Gilroy;
}
.MainMenu_scores_frame__1O6RV {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.MainMenu_score_frame__3wrWJ {
  display: flex;
  flex-direction: row;
}
.MainMenu_score__3z0aw {
  width: 190px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.MainMenu_mark__2YcAj {
  font-weight: 600;
  font-size: 64px;
  line-height: 78px;
  font-family: Gilroy;
}
.MainMenu_comment__2tz-L {
  font-size: 18px;
  line-height: 22px;
  font-family: Gilroy;
  text-align: center;
}
.MainMenu_btn__36rbf {
  width: 100%;
  height: 38.5px;
  background: #000000;
  border-radius: 10px;
  color: white !important;
  font-weight: bold;
  font-family: Gilroy;
  font-size: 18px;
  line-height: 25px;
  transition: 0.2s ease-out;
  border: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.28);
  align-self: flex-start;
  margin-bottom: 14px;
  cursor: pointer;
}
.MainMenu_topic_frame__ZMUQc{
    font-family: Gilroy;
}
.MainMenu_topic_frame_text__1F8i8{
    margin: 10px 0;
}
@media (min-width: 993px) {
  .MainMenu_MainMenu__2mgbh {
    display: flex;
    flex-direction: column;
    padding: 105px 0px;
    position: absolute;
    width: 950px;
    height: 150%;
    left: calc(50% - 950px / 2);
    top: 0px;
  }
}
@media (max-width: 600px) {
  .MainMenu_score_frame__3wrWJ {
    display: flex;
    flex-direction: column;
  }
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Regular.woff");
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-RegularItalic.woff");
    font-style: italic;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-Semibold.woff");
    font-weight: bold;
}

@font-face {
    font-family: Gilroy;
    src: url("https://raw.githubusercontent.com/mavka-org/mavka-web/master/src/fonts/Gilroy-SemiboldItalic.wofff");
    font-weight: bold;
    font-style: italic;
}
